import { Component } from '@angular/core';
import { DataService } from './api/data.service';
import { UserService } from './api/user.service';
import { AuthService } from './auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Kuado', url: 'home', icon: 'home' },
    { title: 'Learn', url: '/learn', icon: 'book' },
    { title: 'Skills', url: '/skills', icon: 'git-branch' },
    /*
    { title: 'Class', url: '/class', icon: 'people' },
    { title: 'School', url: '/school', icon: 'school' },
    { title: 'Org', url: '/org', icon: 'business' },
     */
    { title: 'Settings', url: 'settings', icon: 'settings' },
  ];
  public labels = [];
  constructor(
    public auth: AuthService,
    public userService: UserService,
    private dataService: DataService
  ) {
    this.dataService.healthCheck().subscribe();
  }
}
